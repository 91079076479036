import React from 'react';
import { FormattedMessage } from 'react-intl';

import { graphql, useStaticQuery } from 'gatsby';
import LocaleContext from '../../contexts/LocaleContext';
import LocalizedLink from '../LocalizedLink/LocalizedLink';

export const PricingDropdown: React.FC = () => {
  const { allContentfulCountryPricing } = useStaticQuery(
    graphql`
      query {
        allContentfulCountryPricing(sort: {fields: code, order: ASC}) {
          edges {
            node {
              name
              code
              node_locale
              flag {
                localFile {
                  publicURL
                }
              }
            }
          }
        },
      }
    `,
  );

  return (
    <LocaleContext.Consumer>
      {localeCode => {
        const countries =
          allContentfulCountryPricing.edges
          .filter((edge: any) => edge.node.node_locale === localeCode);

        return <div className="dropdown-section py-4">
          <div className="pricing-selector">
            <h6 className="text-dark-gray-3 px-4">
              <FormattedMessage id="navigation.by_country" />
            </h6>
            {
              countries.map(({node}: any) => (
                <LocalizedLink
                  to={`/pricing/${node.code.toLowerCase()}`}
                  className="dropdown-item"
                  activeClassName="dropdown-item--active"
                  key={node.code}
                >
                  <img height="24" width="24" src={node.flag.localFile.publicURL} className="mr-4 country-ball" alt={`${node.name} flag`} />{node.name}
                </LocalizedLink>
              ))
            }
          </div>

        </div>
      }}
    </LocaleContext.Consumer>

  )
}
