import React from 'react';

import burgerMenuDefaultImg from './assets/images/burgerMenuDefault.svg';
import burgerMenuLightImg from './assets/images/burgerMenuLight.svg';

interface BurgerProps {
  theme:  'light' | 'default';
  onClick: () => void;
};

const BurgerMenu: React.FC<BurgerProps> = (props) => (
  <>
    {
      props.theme === 'light' ?
        <img
          src={burgerMenuLightImg}
          alt=""
          className="mobile-nav-btn"
          onClick={props.onClick}
        />
      :
        <img
          src={burgerMenuDefaultImg}
          alt=""
          className="mobile-nav-btn"
          onClick={props.onClick}
        />
    }
  </>
);


export default BurgerMenu;
