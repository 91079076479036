import React from 'react';
import { FormattedMessage } from 'react-intl';

import { graphql, useStaticQuery } from 'gatsby';
import LocaleContext from '../../contexts/LocaleContext';

import LocalizedLink from '../LocalizedLink/LocalizedLink';

const PricingSection: React.FC = () => {
  const { allContentfulCountryPricing } = useStaticQuery(
    graphql`
      query {
        allContentfulCountryPricing(sort: {fields: code, order: ASC}) {
          edges {
            node {
              name
              code
              node_locale
              flag {
                localFile {
                  publicURL
                }
              }
            }
          }
        },
      }
    `,
  );

  return (
    <div className="mobile-nav__pricing-section">
      <h6 className="text-dark-gray-3 mobile-nav-label mb-3">
        <FormattedMessage id="navigation.pricing"/>
      </h6>
      <div className="row">
        <LocaleContext.Consumer>
          {localeCode => {
            const pricings =
            allContentfulCountryPricing.edges
              .filter((edge: any) => edge.node.node_locale === localeCode);

            return <>{
              pricings.map(({ node }: any) => (
              <div className="col-6" key={node.code}>
                <LocalizedLink
                  to={`/pricing/${node.code.toLowerCase()}`}
                  className="nav-link--service"
                  activeClassName="active"
                >
                  <img src={node.flag.localFile.publicURL} className="mr-4 country-ball" alt={`${node.name} flag`} />{node.name}
                </LocalizedLink>
              </div>
              ))
            }</>
          }}
        </LocaleContext.Consumer>
      </div>

    </div>
  )
};

export default PricingSection;
