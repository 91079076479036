import React, { useState } from 'react'

import { Link } from 'gatsby';
import locales, { Locale } from '../../data/locales';
import useOnClickOutside from '../../hooks/useOnClickOutside';

import globeLightIcon from './assets/images/globe light.svg';
import globeIcon from './assets/images/globe.svg';
import languageIcon from './assets/images/languageIcon.svg';

interface LanguageDropdownProps {
  currentLanguage: string
  pathname: string
  theme?: 'light' | 'default';
}

const LanguageDropdown: React.FC<LanguageDropdownProps> = ({
  currentLanguage,
  pathname,
  theme
}) => {
  const ref = React.createRef<HTMLDivElement>()
  const [dropdownActive, setDropdownActive] = useState(false)
  const selectedLocale = locales.filter(locale=> locale.code === currentLanguage)[0];
  const restOfLocales = locales.filter(locale=> locale.code !== currentLanguage);

  const saveLocale = (locale: Locale) => {
    localStorage.setItem('preferredLanguage', locale.code)
  }

  const getLocalizedPath = (selectedLoc: Locale, locale: Locale, path: string) => {
    if(path.includes(`/${selectedLoc.code}`)) {
      path = path.replace(`/${selectedLoc.code}`, '');
    }

    return locale.default ? path : `/${locale.code}${path}`;
  }

  useOnClickOutside(ref, () => setDropdownActive(false))

  return (
    <div className="dropdown" ref={ref}>
      <button
        className="d-flex align-items-center btn btn-outline navbar-link"
        id="languageMenu"
        onClick={() => setDropdownActive(!dropdownActive)}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded={dropdownActive}
      >
        {
          theme === 'light' ?
          <img src={globeLightIcon} alt="Language icon" className="mr-1" />
          :
          <img src={globeIcon} alt="Language icon" className="mr-1" />
        }
        {selectedLocale.code.toUpperCase()}
        {/* <img src={languageIcon} height="16px" width="auto" alt="Language icon" className="mr-1" />{selectedLocale.code.toUpperCase()} */}
        {/* <img src={messageIcon} height="14px" width="auto" alt="Language icon" className="mr-1" />{selectedLocale.code.toUpperCase()} */}
      </button>
      <div
        className={`dropdown-menu  ${dropdownActive ? 'show' : ''}`}
      >
        {
          restOfLocales.map(locale =>
            <Link
              to={getLocalizedPath(selectedLocale, locale, pathname)}
              key={locale.code}
              onClick={() => saveLocale(locale)}
              className={`dropdown-item text-primary`}
            >
              {locale.name} ({locale.code.toUpperCase()})
            </Link>
          )
        }
      </div>
    </div>
  )
}

export default LanguageDropdown
