import React, {useState, useEffect, useContext} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PricingSection from './PricingSection';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import AccordionSection from '../Accordion/AccordionSection';
import ArrowLink from '../ArrowLink/ArrowLink';
import LocalizedLink from '../LocalizedLink/LocalizedLink';
import LocaleContext from '../../contexts/LocaleContext';


import './styles.scss';

interface MobileNavProps {
  open: boolean
  data: {
    link?: string
    name: string
    external?: boolean
    menu?: boolean
    menuId?: string
    menuClickable?: boolean
  }[]
  location: Location
  close: () => void
}

import ServicesSection from './ServicesSection';
import UnicornStatic from '../UnicornStatic/UnicornStatic';
import LanguageDropdown from '../Navbar/LanguageDropdown';

const MobileNav: React.FC<MobileNavProps> = ({open, data, location, close}) => {
  const localeCode = useContext(LocaleContext);
  const intl = useIntl();
  const [actualPath, setActualPath] = useState("");

  useEffect(() => {
    setActualPath(location.pathname);
    const handleTouchStart = (event: TouchEvent) => {
      if (open && event.touches.length > 0) {
        const touch = event.touches[0];
        const navElement = document.querySelector('.mobile-nav');
        if (navElement && !navElement.contains(touch.target as Node)) {
          // Close the mobile nav if the user touches outside the menu
          close();
        }
      }
    };
    if (open) {
      document.addEventListener('touchstart', handleTouchStart);
    }
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
    };
  }, [open, close]);

  return (
    <>
      <nav
        className={`mobile-nav ${open === true ? 'active' : ''}`}
      >
        <div className="mobile-nav-content-wrapper">
          {
            actualPath.includes('pagos-presenciales') ? null
            : <LanguageDropdown 
            currentLanguage={localeCode}
            pathname={location.pathname}
            theme={'default'}
          />
          }
          
          <ServicesSection/>
          <AccordionSection title={intl.formatMessage({id: 'navigation.industries'})}>
            <LocalizedLink
              to="/industries/ecommerce"
              className="nav-link--service"
              activeClassName="active"
            >
              <FormattedMessage id="navigation.ecommerce" />
            </LocalizedLink>
            <LocalizedLink
              to="/industries/education"
              className="nav-link--service"
              activeClassName="active"
            >
              <FormattedMessage id="navigation.education" />
            </LocalizedLink>
            <LocalizedLink
              to="/industries/tourism"
              className="nav-link--service"
              activeClassName="active"
            >
              <FormattedMessage id="navigation.tourism" />
            </LocalizedLink>
            <LocalizedLink
              to="/industries/services"
              className="nav-link--service"
              activeClassName="active"
            >
              <FormattedMessage id="navigation.basic_services" />
            </LocalizedLink>
          </AccordionSection>
          <PricingSection/>
          <AccordionSection title={intl.formatMessage({id: 'navigation.payment_methods'})}>
            <LocalizedLink
              to="/payments/card"
              className="nav-link--service"
              activeClassName="active"
            >
              <FormattedMessage id="navigation.cards" />
            </LocalizedLink>
            <LocalizedLink
              to="/payments/transfer"
              className="nav-link--service"
              activeClassName="active"
            >
              <FormattedMessage id="navigation.transfer" />
            </LocalizedLink>
            <LocalizedLink
              to="/payments/cash"
              className="nav-link--service"
              activeClassName="active"
            >
              <FormattedMessage id="navigation.cash" />
            </LocalizedLink>
          </AccordionSection>

          <div className="nav-separator mt-4" />

          <div className="nav-separator" />

          <div className="row my-4 mx-5">
            <div className="col-6">

              <LocalizedLink to="/aboutUs" className="nav-link--service mb-2">
                <FormattedMessage id="navigation.company" />
              </LocalizedLink>
              <LocalizedLink to="/customers" className="nav-link--service mb-2">
                <FormattedMessage id="navigation.customers" />
              </LocalizedLink>
              <LocalizedLink to="/initiatives" className="nav-link--service mb-2">
                <FormattedMessage id="navigation.initiatives" />
              </LocalizedLink>

            </div>
            <div className="col-6">
              <LocalizedLink to="/blog" className="nav-link--service mb-2">
                <FormattedMessage id="navigation.blog" />
              </LocalizedLink>
              <OutboundLink
                href="https://docs.kushki.com/"
                className="nav-link--service mb-2"
              >
                <FormattedMessage id="navigation.documentation" />
              </OutboundLink>
              <OutboundLink
                href="https://www.kushkimundial.com"
                className="nav-link--service mb-2"
              >
                <FormattedMessage id="navigation.kushki-mundial" />
              </OutboundLink>
            </div>
          </div>

          <div className="bg-light-gray-1-5 pt-4 pb-layout-6 px-5 flex-grow-1">
            <h6 className="text-dark-gray-3 mobile-nav-label mb-3"><FormattedMessage id="navigation.sign_in" /></h6>
            <ArrowLink
              external={true}
              color="primary"
              openInNewTab={true}
              to="https://uat-console.kushkipagos.com/"
              rel="noopener"
              className="nav-link--service"
            >
              <FormattedMessage id="navigation.test_environment" />
            </ArrowLink>
            <ArrowLink
              external={true}
              color="primary"
              openInNewTab={true}
              to="https://console.kushkipagos.com/"
              rel="noopener"
              className="nav-link--service"
            >
              <FormattedMessage id="navigation.production_environment" />
            </ArrowLink>
            
          </div>
        </div>
      </nav>
      <div className={`backdrop ${open ? 'active' : ''}`} onClick={close} />
    </>
  );
}


export default MobileNav;
