import React from 'react';
import { Flipped } from 'react-flip-toolkit';

interface DropdownContainerProps {
  animatingIn: boolean;
  animatingOut: boolean;
}

export class DropdownContainer extends React.Component<DropdownContainerProps> {
  public render() {
    return (
      <div
        className={
          `dropdown-root
          ${this.props.animatingIn ? 'dropdown-root--animating-in' :  ''}
          ${this.props.animatingOut ? 'dropdown-root--animating-out' :  ''}`
        }
      >
        <Flipped flipId="dropdown-caret">
          <div className="caret" />
        </Flipped>
        <Flipped flipId="dropdown">
          <div className="dropdown-background">
            <Flipped inverseFlipId="dropdown" scale={true}>
              {this.props.children}
            </Flipped>
          </div>
        </Flipped>
      </div>
    )
  }
}
