import React, { useEffect, useState } from 'react';
import { Flipper } from 'react-flip-toolkit';

import LocaleContext from '../../contexts/LocaleContext';
import LocalizedLink from '../LocalizedLink/LocalizedLink';

import LogoLight from './assets/images/logo-light.inline.svg';
import Logo from './assets/images/logo.inline.svg';
import BurgerMenu from './BurgerMenu';
import './styles.scss';
import { CompanyDropdown } from './CompanyDropdown';
import { IntegrationsDropdown } from './IntegrationsDropdown';
import { ServicesDropdown } from './ServicesDropdown';
import { DropdownContainer } from './DropdownContainer';
import { PricingDropdown } from './PricingDropdown';
import { NavLink } from './NavLink';
import LanguageDropdown from './LanguageDropdown';
import UnicornScroll from '../UnicornScroll/UnicornScroll';

interface NavbarProps {
  data: {
    link?: string
    name: string
    external?: boolean
    menu?: boolean
    menuId?: string
    menuClickable?: boolean
  }[]
  theme?: 'light' | 'default'
  location: Location
  openMobileNav: () => void
}

interface NavbarState {
  accountDropdownShow: boolean;
  activeDropdownId?: string;
  dropdownAnimatingIn: boolean;
  dropdownAnimatingOut: boolean;
}

const navbarDropdowns = [
  {
    dropdown: ServicesDropdown,
    id: 'Services',
  },
  {
    dropdown: PricingDropdown,
    id: 'Pricing',
  },
  {
    dropdown: IntegrationsDropdown,
    id: 'Integrations',
  },
  {
    dropdown: CompanyDropdown,
    id: 'Company'
  }
]

class Navbar extends React.Component<NavbarProps, NavbarState> {
  private animatingInTimeout: NodeJS.Timer | undefined;
  private animatingOutTimeout: any | undefined;

  constructor(props: NavbarProps) {
    super(props);

    this.toggleAccountDropdown = this.toggleAccountDropdown.bind(this);

    this.state = {
      accountDropdownShow: false,
      dropdownAnimatingIn: false,
      dropdownAnimatingOut: false,
    };
  }

  public toggleAccountDropdown() {
    this.setState({
      ...this.state,
      accountDropdownShow: !this.state.accountDropdownShow
    });
  }

  public resetDropdownState = (id: any) => {
    this.setState({
      ...this.state,
      activeDropdownId: typeof id === 'string' ? id : undefined,
      dropdownAnimatingIn: false,
      dropdownAnimatingOut: false,
    })

    delete this.animatingOutTimeout;
    delete this.animatingInTimeout;
  }

  public onMouseEnter = (id: any) => {
    if (this.animatingOutTimeout) {
      clearTimeout(this.animatingOutTimeout);
      this.resetDropdownState(undefined);
    }

    if (this.state.activeDropdownId && this.state.activeDropdownId === id) {
      return;
    }

    this.setState({
      ...this.state,
      activeDropdownId: id,
      dropdownAnimatingIn: !this.state.activeDropdownId
    });

    if(!this.state.activeDropdownId) {
      this.animatingInTimeout = setTimeout(() => {
        this.resetDropdownState(id)
      }, 200)
    }
  };

  public onMouseLeave = () => {
    this.setState({
      ...this.state,
      dropdownAnimatingOut: true,
    });

    this.animatingOutTimeout = setTimeout(
      this.resetDropdownState,
      200
    )
  };

  public render() {
    let CurrentDropdown: any;
    let actualPath = this.props.location.pathname;

    const activeNavbarDropdown = navbarDropdowns.filter((navbarDropdown) => {
      return navbarDropdown.id === this.state.activeDropdownId;
    })[0];

    if (activeNavbarDropdown) {
      CurrentDropdown = activeNavbarDropdown.dropdown;
    } else {
      CurrentDropdown = navbarDropdowns[0].dropdown;
    }

    

    return (
      <>
        <nav
          className={
            'navbar navbar-toggleable-sm ' +
            `${this.props.theme === 'light' ?  'navbar--light' : ''}`
          }
          onMouseLeave={this.onMouseLeave}
          >
          <div className="container d-flex justify-content-between align-items-center mx-0 mx-sm-auto">
            <div className="nav-logo">
              <LocalizedLink to="/">
                { this.props.theme === 'light' ? <LogoLight/>: <Logo /> }
              </LocalizedLink>
            </div>
            <BurgerMenu
              onClick={this.props.openMobileNav}
              theme={this.props.theme ? this.props.theme : 'default'}
              />
            
            <Flipper
              flipKey={this.state.activeDropdownId}
              spring={'noWobble'}
              className="d-none d-lg-inline-block">
              <div className="nav-links">
                  {this.props.data.map((navLink) =>
                    <NavLink
                    title={navLink.name}
                    external={navLink.external}
                    menuId={navLink.menuId}
                    key={navLink.name}
                    link={navLink.link}
                    onMouseEnter={this.onMouseEnter}
                    active={!!navLink.menuId && this.state.activeDropdownId === navLink.menuId}
                    >
                      {navLink.menuId && this.state.activeDropdownId === navLink.menuId && (
                        <DropdownContainer
                        animatingIn={this.state.dropdownAnimatingIn}
                        animatingOut={this.state.dropdownAnimatingOut}
                        >
                          <CurrentDropdown />
                        </DropdownContainer>
                      )}
                    </NavLink>
                  )}
                
              </div>
            </Flipper>

            {
              actualPath.includes('pagos-presenciales') ? null
              : <div className="d-none d-lg-flex">
              <LanguageDropdown
                currentLanguage={this.context}
                pathname={this.props.location.pathname}
                theme={this.props.theme}
                />
            </div>
            }
          </div>
        </nav>
      </>
    );
  }
}

Navbar.contextType = LocaleContext;

export default Navbar;
