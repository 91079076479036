import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import LocaleContext from '../../contexts/LocaleContext';
import LocalizedLink from '../LocalizedLink/LocalizedLink';
import industryIconImg from './assets/images/icon-industry.svg';
import kushkiIconImg from './assets/images/icon-kushki.svg';
import blogIconImg from './assets/images/icon-pen-tool.svg';
import TrelloImg from './assets/images/trello.inline.svg';
import securityIconImg from './assets/images/icon-security.svg';
import cloudIconImg from './assets/images/icon-cloud.svg';
import StoreIcon from './assets/images/icon-store.svg';
import { Book } from '@kushki/suka-react-system-icons';

import ArrowLink from '../ArrowLink/ArrowLink';


interface BlogPost {
  title: string;
  slug: string;
  locale: string;
}

export const CompanyDropdown: React.FC = () => {
  const { blogPosts } = useStaticQuery(
    graphql`
      query {
        blogPosts: allContentfulBlogPost(sort: {order: DESC, fields: date}, limit: 6) {
          nodes {
            title
            slug
            locale: node_locale
          }
        }
      }
    `,
  );

  return (
    <LocaleContext.Consumer>
      {localeCode => (
        <div className="dropdown-section">
          <div className="company-selector">
            <div className="py-4
            ">
              <LocalizedLink
                to="/aboutUs"
                className="dropdown-item"
                activeClassName="dropdown-item--active"
              >
                <img src={kushkiIconImg} alt="Kushki icon" className="mr-2" />
                <FormattedMessage id="navigation.about_us" />
              </LocalizedLink>
              <LocalizedLink
                to="/customers"
                className="dropdown-item"
                activeClassName="dropdown-item--active"
              >
                <img src={StoreIcon} className="mr-2 icon-book" />
                <FormattedMessage id="navigation.customers" />
              </LocalizedLink>
              <LocalizedLink
                to="/security"
                className="dropdown-item"
                activeClassName="dropdown-item--active"
              >
                <img src={securityIconImg} alt="Security icon" className="mr-2" />
                <FormattedMessage id="navigation.security" />
              </LocalizedLink>
              <LocalizedLink
                to="/partneraws/"
                className="dropdown-item"
                activeClassName="dropdown-item--active"
              >
                <img src={cloudIconImg} alt="partnerAws icon" className="mr-2 aws-icon" />
                <FormattedMessage id="partner_aws.link" />
              </LocalizedLink>
              <LocalizedLink
                to={localeCode == "es" ? "/papers-y-webinars/" : "/papers-and-webinars/"}
                className="dropdown-item"
                activeClassName="dropdown-item--active"
              >
                <Book className="mr-2 icon-book" />
                <FormattedMessage id="navigation.ebooksGallery" />
              </LocalizedLink>
              <LocalizedLink
                to="/gobcorp"
                className="dropdown-item"
                activeClassName="dropdown-item--active"
              >
                <img src={industryIconImg} alt="Customers icon" className="mr-2" />
                <FormattedMessage  id="gobcorp.title" />
              </LocalizedLink>
            </div>
            <div className="pt-2 pb-5 bg-light-gray-1-5">
              <LocalizedLink
                to="/blog"
                className="dropdown-item"
                activeClassName="dropdown-item--active"
              >
                <img src={blogIconImg} alt="Blog icon" className="mr-2" />
                <FormattedMessage id="navigation.blog" />
              </LocalizedLink>
              {/* <h6 className="text-dark-gray-3 pl-layout-2"><small>Últimas entradas</small></h6> */}
              {
                blogPosts.nodes
                .filter((blogPost: BlogPost) => blogPost.locale === localeCode)
                .map((blogPost: BlogPost) => (
                  <ArrowLink
                    to={`/blog/${blogPost.slug}`}
                    className="blog-link-item"
                    key={blogPost.slug}
                  >
                    <span className="blog-link-item__label">{blogPost.title}</span>
                  </ArrowLink>
                ))
              }
            </div>
          </div>
        </div>
      )}
    </LocaleContext.Consumer>
  )
}
