import React, { ReactNode, FC } from 'react';

import { OutboundLink } from 'gatsby-plugin-google-gtag';
import LocalizedLink from '../LocalizedLink/LocalizedLink';

import './styles.scss';

interface ArrowLinkProps {
  className?: string;
  children?: ReactNode;
  to: string;
  color?: 'primary' | 'brand-green' | 'primary-light';
  external?: boolean;
  openInNewTab?: boolean;
  rel?: string;
}

const ArrowLink: FC<ArrowLinkProps & React.HTMLProps<HTMLAnchorElement>> = ({to, children, className, color, external, openInNewTab, rel}) => (
  <>
    {
      external ?
      <OutboundLink
        href={to}
        target={openInNewTab ? '_blank' : ''}
        className={`${className} arrow-link arrow-link--${color}`}
        rel={rel}
      >
        {children}
        <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path d="M5.0866 1.62036L9.43667 6.50005L5.0866 11.3797M0.3479 6.50005H9.43667H0.3479Z" stroke="#000000" strokeWidth="1.875" />
          </g>
        </svg>
      </OutboundLink>
    :
      <LocalizedLink
        to={to}
        className={`${className} arrow-link arrow-link--${color}`}
        rel={rel}
      >
        {children}
        <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path d="M5.0866 1.62036L9.43667 6.50005L5.0866 11.3797M0.3479 6.50005H9.43667H0.3479Z" stroke="#000000" strokeWidth="1.875" />
          </g>
        </svg>
      </LocalizedLink>
    }
  </>

);

export default ArrowLink;
