import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import LocalizedLink from '../LocalizedLink/LocalizedLink';
import './styles.scss'

interface NavLinkProps {
  onMouseEnter: (id?: string) => void;
  children?: React.ReactNode;
  menuId?: string;
  title: string;
  link?: string;
  external?: boolean;
  active?: boolean;
}

export class NavLink extends React.Component<NavLinkProps> {
  public onMouseEnter = () => {
    this.props.onMouseEnter(this.props.menuId)
  }

  public render() {
    const { title, children } = this.props;
    return (

      <div className={`navbar-item ${this.props.active ? 'active' : ''}`}>
        {
          this.props.external &&
          <OutboundLink
            href={this.props.link}
            className="navbar-link"
            onMouseEnter={this.onMouseEnter}
            onFocus={this.onMouseEnter}
          >
            <FormattedMessage id={title} />
          </OutboundLink>
        }
        {
          !this.props.external && this.props.link &&
          <LocalizedLink
            to={this.props.link}
            className="navbar-link"
            onMouseEnter={this.onMouseEnter}
            onFocus={this.onMouseEnter}
            activeClassName="active"
          >
            <FormattedMessage id={title} />
          </LocalizedLink>
        }
        {
          !this.props.link &&
          <div
            className="navbar-link"
            onMouseEnter={this.onMouseEnter}
            onFocus={this.onMouseEnter}
          >
            <FormattedMessage id={title} />
          </div>
        }
        <div className="navbar-item__dropdown-slot">
          {children}
        </div>
      </div>
    );
  }
}
