import React from 'react';
import { FormattedMessage } from 'react-intl';

import LocalizedLink from '../LocalizedLink/LocalizedLink';

import cajitaImg from '../Navbar/assets/images/cajita.svg';
import paymentsImg from '../Navbar/assets/images/payments.svg';
import smartlinkImg from '../Navbar/assets/images/smartlink.svg';
import subscriptionImg from '../Navbar/assets/images/subscription.svg';
import cardImg from '../Navbar/assets/images/card.svg'
import LocaleContext from '../../contexts/LocaleContext';

const ServicesSection: React.FC = () => {

  return (
    <div className="mobile-nav__services-section">
      <h6 className="text-dark-gray-3 mobile-nav-label mb-3"><FormattedMessage id="navigation.services" /></h6>

      <LocalizedLink
        to={`/products`}
        className="nav-link--service"
        activeClassName="active"
      >
        <img src={paymentsImg} alt="Pagos icon" />
        <FormattedMessage id="navigation.payment_processing" />
      </LocalizedLink>
      <LocalizedLink
        to={`/features/cajita`}
        className="nav-link--service"
        activeClassName="active"
      >
        <img src={cajitaImg} alt="Cajita icon" />
        <FormattedMessage id="navigation.checkout" />
      </LocalizedLink>
      <LocalizedLink
        to={`/features/subscriptions`}
        className="nav-link--service"
        activeClassName="active"
      >
        <img src={subscriptionImg} alt="Subscription icon" />
        <FormattedMessage id="navigation.subscriptions" />
      </LocalizedLink>
      <LocalizedLink
        to={`/features/link-de-pago-smartlink`}
        className="nav-link--service"
        activeClassName="active"
      >
        <img src={smartlinkImg} alt="Smartlink icon" />
        <FormattedMessage id="navigation.smartlink" />
      </LocalizedLink>
      <LocaleContext.Consumer>
        {localeCode => {

          if (localeCode === 'en') return null
          return <LocalizedLink
            to={`/pagos-presenciales`}
            className="nav-link--service"
            activeClassName="active"
          >
            <img src={cardImg} alt="Smartlink icon" />
            <FormattedMessage id="navigation.card_solution" />
          </LocalizedLink>
        }}
      </LocaleContext.Consumer>
      
    </div>
  );
};


export default ServicesSection;
