import React from 'react'

import { GatsbyLinkProps, Link } from 'gatsby'
import LocaleContext from '../../contexts/LocaleContext';

import locales from '../../data/locales';

const LocalizedLink: React.FC<Omit<GatsbyLinkProps<{}>, 'ref'>> = ({to, ...props }) => {
  return (<LocaleContext.Consumer>
    {localeCode => {
      const locale = locales.filter(l => l.code === localeCode)[0];

      const path = locale.default ? to : `/${locale.path}${to}`

      return <Link {...props} to={path} />
    }}
  </LocaleContext.Consumer>)
}

export default LocalizedLink;
