import React from 'react';
import { FormattedMessage } from 'react-intl';

import { OutboundLink } from 'gatsby-plugin-google-gtag';

import magentoImg from './assets/images/magento.svg';
import prestashopImg from './assets/images/prestashop.svg';
import vtexImg from './assets/images/vtex.svg';
import woocommerceImg from './assets/images/woocommerce.svg';
import shoppify from './assets/images/shopify.png'



export const IntegrationsDropdown: React.FC = () => {

  const sendAnalyticsEvent = (url: string) => {

    if(typeof window !== 'undefined') {
      window.gtag('event', `Click`, {
        'event_category': 'Outbound',
        'event_label': `${url}`,
      })
    }

    return true
  }

  return (
    <div className="dropdown-section">
      <div className="integrations-selector">
        <div className="integrations-selection__main-list">
          <OutboundLink
            href="https://docs.kushki.com/"
            className="integrations-selection__main-link"
          >
            <h6 className="integrations-selection__main-link__title">
              <FormattedMessage id="navigation.docs" />
            </h6>
            <p className="integrations-selection__main-link__subtitle">
              <FormattedMessage id="navigation.docs_description" />
            </p>
          </OutboundLink>
          <OutboundLink
            href="https://soporte.kushkipagos.com/"
            className="integrations-selection__main-link"
          >
            <h6 className="integrations-selection__main-link__title">
              <FormattedMessage id="navigation.knowledge_base" />
            </h6>
            <p className="integrations-selection__main-link__subtitle">
              <FormattedMessage id="navigation.knowledge_base_description" />
            </p>
          </OutboundLink>
        </div>
        <div className="integrations-selection__side-list">
          <div>
            <h1 className="label">
              <FormattedMessage id="navigation.ecommerce_plugins" />
            </h1>
            <OutboundLink
              href="https://docs.kushki.com/ec/plugins/woocommerce"
              className="integrations-selection__side-link"
              target="_blank"
              rel="noopener"
              onClick={() => sendAnalyticsEvent('https://docs.kushki.com/ec/plugins/woocommerce')}
            >
              <img src={woocommerceImg} alt="Woocommerce" className="mr-2"/>
              Woocommerce
            </OutboundLink>
            <OutboundLink
              href="https://docs.kushki.com/ec/plugins/magento"
              className="integrations-selection__side-link"
              target="_blank"
              rel="noopener"
              onClick={() => sendAnalyticsEvent('https://docs.kushki.com/ec/plugins/magento')}
            >
              <img src={magentoImg} alt="Magento" className="mr-2"/>
              Magento
            </OutboundLink>
            <OutboundLink
              href="https://docs.kushki.com/ec/plugins/prestashop"
              className="integrations-selection__side-link"
              target="_blank"
              rel="noopener"
              onClick={() => sendAnalyticsEvent('https://docs.kushki.com/ec/plugins/prestashop')}
            >
              <img src={prestashopImg} alt="Prestashop" className="mr-2"/>
              Prestashop
            </OutboundLink>
            <OutboundLink
              href="https://docs.kushki.com/ec/en/plugins/shopify/shopify-kushki"
              className="integrations-selection__side-link"
              target="_blank"
              rel="noopener"
            >
              <img src={shoppify} alt="shopify" className="mr-2" width={15}/>
              Shopify
            </OutboundLink>
            <OutboundLink
              href={'https://docs.kushki.com/co/en/plugins/kushki-integration-vtex/vtex-kushki'}
              className="integrations-selection__side-link"
              target="_blank"
              rel="noopener"
            >
              <img src={vtexImg} alt="VTEX" className="mr-2"/>
              VTEX
            </OutboundLink>
          </div>
  
          <div className="ml-layout-3">
            <h1 className="label">
              <FormattedMessage id="navigation.developers" />
            </h1>
            <OutboundLink
              href="https://api-docs.kushkipagos.com/api-reference"
              className="integrations-selection__side-link"
  
            >
              <FormattedMessage id="navigation.api_reference" />
            </OutboundLink>
            <OutboundLink
              href="https://api-docs.kushkipagos.com/kushki-libraries"
              className="integrations-selection__side-link"
  
            >
              <FormattedMessage id="navigation.libraries" />
            </OutboundLink>
            <OutboundLink
              href="https://status.kushkipagos.com/"
              className="integrations-selection__side-link"
  
            >
              <FormattedMessage id="navigation.service_status" />
            </OutboundLink>
          </div>
        </div>
      </div>
  
    </div>
  )
} 
