import React, {useState, useEffect, FC} from 'react';
import './styles.scss';

interface AccordionSectionProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  openProp?: boolean;
  clickEvent?: (elementId: number) => void;
  elementId?: number;
}

interface AccordionSectionState {
  open: boolean;
  class: string;
}

const AccordionSection: FC <AccordionSectionProps> = ({
  title,
  children,
  openProp,
  clickEvent,
  elementId,
}) => {

  const [stateOpen, setStateOpen] = useState({
      class: openProp ? 'accordion-section open' : 'accordion-section',
      open: openProp || false,
    });

  const handleClick = () => {
    if (stateOpen.open) {
      setStateOpen({
        class: 'accordion-section',
        open: false,
      });
    } else {
      setStateOpen({
        class: 'accordion-section open',
        open: true,
      });
    }
    if (clickEvent && clickEvent(elementId)) {
      clickEvent(elementId);
    }
  }

  const getInitialState = () => {
    return {
      class: 'accordion-section',
      open: false,
    }
  }

  useEffect(() => {
    setStateOpen({
      class: openProp ? 'accordion-section open' : 'accordion-section',
      open: openProp || false,
    });
  }, [openProp]);

    return (
      <div className={stateOpen.class}>
        <button>toggle</button>
        <div className="accordion-section-head" onClick={handleClick}>{title}</div>
        <div className="article-wrapper">
          <div className="article">
            {children}
          </div>
        </div>
      </div>
    );
}

export default AccordionSection;
