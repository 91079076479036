import * as React from 'react';

import { graphql, navigate, StaticQuery } from 'gatsby';

import browserLang from 'browser-lang';

import flatten from 'flat';

import { IntlProvider } from 'react-intl';

import locales, { LocaleTypes } from '../data/locales';

import LocaleContext from '../contexts/LocaleContext';

import MobileNav from '../components/MobileNav/MobileNav';
import Navbar from '../components/Navbar/Navbar';

import '../styles/index.scss';

import { Helmet } from "react-helmet"

interface StaticQueryProps {
  site: {
    siteMetadata: {
      menuLinks: {
        link?: string;
        name: string;
        external?: boolean;
        menu?: boolean;
        menuId?: string;
        menuClickable?: boolean;
      }[];
    };
  };
}

interface LayoutState {
  mobileMenuOpen: boolean;
}

type ChildProps = LayoutState;

interface LayoutProps {
  navbarTheme?: 'light' | 'default';
  locale: LocaleTypes;
  location: Location;
  page?: string;
  render(state: ChildProps): React.ReactNode;
}

import en from '../i18n/en.json';
import es from '../i18n/es.json';

const messages = { en, es };

export default class IndexLayout
  extends React.Component<LayoutProps, LayoutState> {

  // tslint:disable-next-line: variable-name
  private _locale: LocaleTypes;

  constructor(props: LayoutProps) {
    super(props);
    this.openMobileMenu = this.openMobileMenu.bind(this);
    this.closeMobileMenu = this.closeMobileMenu.bind(this);
    this._locale = props.locale ? props.locale : 'es';
    
    this.state = {
      mobileMenuOpen: false,
    };
  }

  public openMobileMenu(): void {
    this.setState({
      ...this.state,
      mobileMenuOpen: true,
    });
  }

  public closeMobileMenu(): void {
    this.setState({
      ...this.state,
      mobileMenuOpen: false,
    });
  }

  public getPreferredLanguage(): string {
    let preferredLanguage = localStorage.getItem('preferredLanguage');

    if(preferredLanguage === null) {
      preferredLanguage = `${browserLang({
        fallback: 'en',
        languages: locales.map(locale => locale.code),
      })}`;

      localStorage.setItem('preferredLanguage', preferredLanguage);
    }

    return preferredLanguage;
  }

  public componentDidMount(): void {
    const preferredLanguage = this.getPreferredLanguage();

    console.log('----->>>>>', preferredLanguage)
    if(this._locale !== preferredLanguage) {
      const locale = locales.filter(l => l.code === preferredLanguage)[0];
      console.log(locale)
      const localizedPath = locale.default
      ? `${this.props.location.pathname}`
      : `/${locale.path}${this.props.location.pathname}`;
      navigate(localizedPath);
    }
  }

  renderPage(data: any) {
    if (this.props.page && this.props.page === 'launchpad') {
      return (
        <main className="bg-primary" 
        style={{
          height: '100vh',
          display: 'grid',
          gridAutoRows: '17rem 1fr 4rem',
        }} >
          {
            this.props.render(
              {
                ...this.state,
              },
            )
          }
        </main>
      )
    } else {
      return (
        <>
          <MobileNav
            data={data.site.siteMetadata.menuLinks}
            open={this.state.mobileMenuOpen}
            close={this.closeMobileMenu}
            location={this.props.location}
          />
          <main className={this.state.mobileMenuOpen ? 'mobile-nav-open' : ''}>
            <Navbar
              openMobileNav={this.openMobileMenu}
              data={data.site.siteMetadata.menuLinks}
              theme={this.props.navbarTheme}
              location={this.props.location}
            />
            {
              this.props.render(
                {
                  ...this.state,
                },
              )
            }
          </main>
        </>
      )   
    }
  }

  public render() {
    return (
      <><Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-652396226"></script>
        <script>
          {`
         window.dataLayer = window.dataLayer || [];
         function gtag(){dataLayer.push(arguments);}
         gtag('js', new Date());
         gtag('config', 'AW-652396226');
        `}
        </script>
      </Helmet><StaticQuery
          query={graphql`
            query IndexLayoutQuery {
              site {
                siteMetadata {
                  menuLinks {
                    menu
                    menuId
                    name
                    external
                    link
                  }
                }
              }
            }
          `}
          render={(data: StaticQueryProps) => (
            <LocaleContext.Provider value={this._locale}>
              <IntlProvider locale={this._locale} messages={flatten(messages[this._locale])}>
                {this.renderPage(data)}
              </IntlProvider>
            </LocaleContext.Provider>
          )} /></>
    );
  }
}
