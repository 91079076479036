import React from 'react';
import { FormattedMessage } from 'react-intl';

import LocalizedLink from '../LocalizedLink/LocalizedLink';

import cajitaImg from './assets/images/cajita.svg';
import paymentsImg from './assets/images/payments.svg';
import smartlinkImg from './assets/images/smartlink.svg';
import subscriptionImg from './assets/images/subscription.svg';
import buttonPayImg from './assets/images/shield-finger.svg';
import pluginsImg from './assets/images/plugins.svg'
import cardImg from './assets/images/card.svg'
import LocaleContext from '../../contexts/LocaleContext';
import locales from '../../data/locales';

export const ServicesDropdown: React.FC = () => (
  <div className="dropdown-section">
    <div className="services-selector">
      <div className="services-selection__main-list">
        <LocalizedLink
          to={`/products`}
          className="services-selection__main-link"
          activeClassName="active"
        >
          <img src={paymentsImg} alt="Payments icon" />
          <div>
            <h6 className="services-selection__main-link__title">
              <FormattedMessage id="navigation.payment_processing" />
            </h6>
            <p className="services-selection__main-link__subtitle">
              <FormattedMessage id="navigation.payment_processing_description" />
            </p>
          </div>
        </LocalizedLink>
        <LocalizedLink
          to={`/features/cajita`}
          className="services-selection__main-link"
          activeClassName="active"
        >
          <img src={cajitaImg} alt="Cajita icon" />
          <div>
            <h6 className="services-selection__main-link__title">
            <FormattedMessage id="navigation.checkout" />
            </h6>
            <p className="services-selection__main-link__subtitle">
              <FormattedMessage id="navigation.checkout_description" />
            </p>
          </div>
        </LocalizedLink>
        <LocalizedLink
          to={`/features/subscriptions`}
          className="services-selection__main-link"
          activeClassName="active"
        >
          <img src={subscriptionImg} alt="Subscriptions icon" />
          <div>
            <h6 className="services-selection__main-link__title">
              <FormattedMessage id="navigation.subscriptions" />
            </h6>
            <p className="services-selection__main-link__subtitle">
              <FormattedMessage id="navigation.subscriptions_description" />
            </p>
          </div>
        </LocalizedLink>
        <LocalizedLink
          to={`/features/link-de-pago-smartlink`}
          className="services-selection__main-link"
          activeClassName="active"
        >
          <img src={smartlinkImg} alt="Smartlink icon" />
          <div>
            <h6 className="services-selection__main-link__title">
              <FormattedMessage id="navigation.smartlink" />
            </h6>
            <p className="services-selection__main-link__subtitle">
              <FormattedMessage id="navigation.smartlink_description" />
            </p>
          </div>
        </LocalizedLink>
        <LocalizedLink
          to={`/features/buttonPay`}
          className="services-selection__main-link"
          activeClassName="active"
        >
          <img src={buttonPayImg} alt="ButtonPay icon" />
          <div>
            <h6 className="services-selection__main-link__title">
              <FormattedMessage id="navigation.button_pay" />
            </h6>
            <p className="services-selection__main-link__subtitle">
              <FormattedMessage id="navigation.button_pay_description" />
            </p>
          </div>
        </LocalizedLink>
        <LocalizedLink
          to={`/features/plugins`}
          className="services-selection__main-link"
          activeClassName="active"
        >
          <img src={pluginsImg} alt="ButtonPay icon" />

          <div>
            <h6 className="services-selection__main-link__title">
              <FormattedMessage id="navigation.plugins" />
            </h6>
            <p className="services-selection__main-link__subtitle">
              <FormattedMessage id="navigation.plugins_description" />
            </p>
          </div>
        </LocalizedLink>
        <LocaleContext.Consumer>
          {localeCode => {
            if(localeCode === 'en') return null;
            return <LocalizedLink
              to={`/pagos-presenciales`}
              className="services-selection__main-link"
              activeClassName="active"
            >
              <img src={cardImg} alt="ButtonPay icon" />

              <div>
                <h6 className="services-selection__main-link__title">
                  <FormattedMessage id="navigation.card_solution" />
                </h6>
                <p className="services-selection__main-link__subtitle">
                  <FormattedMessage id="navigation.card_solution_description" />
                </p>
              </div>
            </LocalizedLink>
          }}
      </LocaleContext.Consumer>
        {/* <LocalizedLink
          to={`/pagos-presenciales`}
          className="services-selection__main-link"
          activeClassName="active"
        >
          <img src={cardImg} alt="ButtonPay icon" />

          <div>
            <h6 className="services-selection__main-link__title">
              <FormattedMessage id="navigation.card_solution" />
            </h6>
            <p className="services-selection__main-link__subtitle">
              <FormattedMessage id="navigation.card_solution_description" />
            </p>
          </div>
        </LocalizedLink> */}
      </div>
      <div className="services-selection__side-list">

        <h1 className="label">
          <FormattedMessage id="navigation.industries" />
        </h1>
        <LocalizedLink
          to="/industries/retail"
          className="services-selection__side-link"
          activeClassName="active"
        >
          <FormattedMessage id="navigation.retail" />
        </LocalizedLink>
        <LocalizedLink
          to="/industries/education"
          className="services-selection__side-link"
          activeClassName="active"
        >
          <FormattedMessage id="navigation.education" />
        </LocalizedLink>
        <LocalizedLink
          to="/industries/tourism"
          className="services-selection__side-link"
          activeClassName="active"
        >
          <FormattedMessage id="navigation.tourism" />
        </LocalizedLink>
        <LocalizedLink
          to="/industries/services"
          className="services-selection__side-link"
          activeClassName="active"
        >
          <FormattedMessage id="navigation.basic_services" />
        </LocalizedLink>
        <LocalizedLink
          to="/industries/psp"
          className="services-selection__side-link"
          activeClassName="active"
        >
          <FormattedMessage id="navigation.psp" />
        </LocalizedLink>


        <h1 className="label mt-layout-3">
          <FormattedMessage id="navigation.payment_methods" />
        </h1>
        <LocalizedLink
          to="/payments/card"
          className="services-selection__side-link"
          activeClassName="active"
        >
          <FormattedMessage id="navigation.cards" />
        </LocalizedLink>
        <LocalizedLink
          to="/payments/transfer"
          className="services-selection__side-link"
          activeClassName="active"
        >
          <FormattedMessage id="navigation.transfer" />
        </LocalizedLink>
        <LocalizedLink
          to="/payments/cash"
          className="services-selection__side-link"
          activeClassName="active"
        >
          <FormattedMessage id="navigation.cash" />
        </LocalizedLink>
        <LocalizedLink
          to="/payouts"
          className="services-selection__side-link"
          activeClassName="active"
        >
          <FormattedMessage id="navigation.payouts" />
        </LocalizedLink>
      </div>
    </div>

  </div>
)
